import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import toast from 'react-hot-toast';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import Spinners from "components/Common/Spinner";
import { createTicket, deleteEvent, deleteTicket, deleteUser, getAllCountries, getAllEventByOrganizer, getAllEvents, getAllOrganizer, getAllTicketByEvent, getAllTicketByEventAndVenue, getEventByQuery, getEventVenuesBySlug, updateTicket, updateUser } from "api";
import { handleError } from "utils/errorHandler";
import moment from "moment";

const EventTickets = () => {

  const navigate = useNavigate();

  //meta title
  document.title = "Event Ticket List | EventoX";

  const [eventList, setEventList] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedEventForAdd, setSelectedEventForAdd] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [ticketList, setTicketList] = useState([])
  const [tickets, setTickets] = useState()
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [eventVenues, setEventVenues] = useState([])
  const [eventVenuesForAdd, setEventVenuesForAdd] = useState([])
  const [selectedVenue, setSelectedVenue] = useState(null)
  const [selectedVenueForAdd, setSelectedVenueForAdd] = useState(null)
  const [status, setStatus] = useState('active')


  useEffect(() => {

    fetchAll();
    return () => {

    }
  }, [])

  const toggle = () => {
    setModal(!modal);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ticketType: (tickets && tickets.ticketType) || "",
      price: (tickets && tickets.price) || "",
      maxCount: (tickets && tickets.maxCount) || 0,
      eventSlug: (tickets && tickets.eventSlug) || "",
      ticketOrder: (tickets && tickets.ticketOrder) || "",
      ticketStatus: (tickets && tickets.ticketStatus) || "",
      venueId: (tickets && tickets.venueId) || "",
    },
    validationSchema: Yup.object({
      ticketType: Yup.string().required("Ticket Type Required"),
      price: Yup.number().required("Price Required"),
      eventSlug: Yup.string().required("Event Required"),
      ticketOrder: Yup.number().required("Required"),
      venueId: Yup.string().required("Venue Required"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        values.ticketStatus = status;
        const updateResp = await updateTicket(tickets.ticketTypeId, values);
        toast.success("Ticket updated successfully");
        setIsEdit(false)
        fetchAllTicket(selectedEvent.value, selectedVenue.value);
        toggle();

      } else {
        values.ticketStatus = 'active';
        const createResp = await createTicket(values);
        toast.success("New ticket added successfully");
        fetchAllTicket(values.eventSlug, values.venueId);
        validation.resetForm();
        console.log(values);
        toggle();
      }
    }
  })
  const fetchAllTicket = async (slugId, venueId) => {
    try {
      setIsLoading(true)
      const resp = await getAllTicketByEventAndVenue(slugId, venueId);
      setTicketList(resp)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleError(error)
    }
  }
  const fetchAll = async () => {
    try {

      const respAllEvent = await getAllEvents();
      const respEventVenue = await getEventVenuesBySlug();
      const paidEvents = respAllEvent.filter((item) => item.ticketType === "paid" && item.paymentChannel === "our")
      const eventOptions = paidEvents.map((item) => {
        return {
          value: item.slugId,
          label: item.eventName
        }
      })
      setEventList(eventOptions);

    } catch (error) {
      console.log(error);
    }
  }


  const getEventVenue = async (eventId) => {
    try {
      const resp = await getEventVenuesBySlug(eventId);
      const venueOptions = resp.map((item) => {
        return {
          value: item.venueId,
          label: item.venueName
        }
      })
      setEventVenues(venueOptions)
    } catch (error) {
      handleError(error)
    }
  }
  const getEventVenueForAdd = async (eventId) => {
    try {
      const resp = await getEventVenuesBySlug(eventId);
      const venueOptions = resp.map((item) => {
        return {
          value: item.venueId,
          label: item.venueName
        }
      })
      setEventVenuesForAdd(venueOptions)
    } catch (error) {
      handleError(error)
    }
  }

  const viewEvent = async (eventData) => {
    window.open('https://qa.eventox.co/event/' + eventData.slugId, '_blank');
  }
  const handleClickedEdit = (data) => {
    console.log("🚀 ~ handleClickedEdit ~ data:", data)
    setTickets(data);
    setIsEdit(true);
    toggle();
  }
  const columns = useMemo(
    () => [

      {
        header: 'Ticket Name',
        accessorKey: 'ticketType',
        enableColumnFilter: false,
        enableSorting: true,

      },

      {
        header: 'Price',
        accessorKey: 'price',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Ticket Order',
        accessorKey: 'ticketOrder',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Max Count',
        accessorKey: 'maxCount',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Current Count',
        accessorKey: 'currentCount',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'ticketStatus',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <div>
              {
                cell.getValue() === 'active' ? (
                  <span className="badge badge-soft-primary font-size-11 m-1">{cell.getValue()}</span>
                ) : (
                  <span className="badge badge-soft-danger font-size-11 m-1">{cell.getValue()}</span>
                )
              }

            </div>
          );
        },
      },
      {
        header: 'Created',
        accessorKey: 'createdAt',
        enableColumnFilter: false,
        enableSorting: true,
      },


      {
        header: 'Action',
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">


                <DropdownItem
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickDelete(data);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const data = cellProps.row.original;
                    handleClickedEdit(data);
                  }}>
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="deletetooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>






              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );
  const handleClickNew = () => {
    toggle();
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (data) => {
    setSelectedTicket(data);
    setDeleteModal(true);
  };

  const handleDeleteEvent = async () => {
    if (selectedTicket && selectedTicket.ticketTypeId) {
      try {

        const resp = await deleteTicket(selectedTicket.ticketTypeId);
        toast.success("Delete ticket successfully");
        fetchAllTicket(selectedTicket.eventSlug)
      } catch (error) {
        toast.error("Error Deleting event " + error?.message);
        handleError(error)
      }

    }
    setDeleteModal(false);
  };






  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Events List" />

          <Row>
            {
              isLoading ? <Spinners setLoading={setIsLoading} />
                :
                <Col lg="12">

                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <Col xl="5">
                          <div className="col-md-12">
                            <Select
                              value={selectedEvent}
                              placeholder="Select Event"
                              onChange={(data) => {
                                setSelectedEvent(data)
                                getEventVenue(data.value)
                              }}
                              options={eventList}
                              width="100%"
                              className="select2-selection"
                            />

                          </div>
                        </Col>
                        <Col xl="5">
                          <div className="col-md-12">
                            <Select
                              value={selectedVenue}
                              placeholder="Select Venue"
                              onChange={(data) => {
                                setSelectedVenue(data)
                                fetchAllTicket(selectedEvent.value, data.value)
                              }}
                              options={eventVenues}
                              width="100%"
                              className="select2-selection"
                            />

                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <TableContainer
                          columns={columns}
                          data={ticketList || []}
                          isGlobalFilter={true}
                          isPagination={true}
                          SearchPlaceholder="Search..."
                          isCustomPageSize={true}
                          isAddButton={true}
                          handleUserClick={handleClickNew}
                          buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                          buttonName="New Ticket"
                          tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                          theadClass="table-light"
                          paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                          pagination="pagination"
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
            }


            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Ticket" : "Add New Ticket"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Ticket Name</Label>
                        <Input
                          name="ticketType"
                          type="text"
                          placeholder="Ticket Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ticketType || ""}
                          invalid={
                            validation.touched.ticketType &&
                              validation.errors.ticketType
                              ? true
                              : false
                          }
                        />
                        {validation.touched.ticketType &&
                          validation.errors.ticketType ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ticketType}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Ticket Amount</Label>
                        <Input
                          name="price"
                          label="Ticket Amount"
                          type="number"
                          placeholder="Ticket Amount"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.price || ""}
                          invalid={
                            validation.touched.price &&
                              validation.errors.price
                              ? true
                              : false
                          }
                        />
                        {validation.touched.price &&
                          validation.errors.price ? (
                          <FormFeedback type="invalid">
                            {validation.errors.price}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Max Count</Label>
                        <Input
                          name="maxCount"
                          label="Max Count"
                          type="text"
                          placeholder="Enter Max Count"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.maxCount || ""}
                          invalid={
                            validation.touched.maxCount &&
                              validation.errors.maxCount
                              ? true
                              : false
                          }
                        />
                        {validation.touched.maxCount &&
                          validation.errors.maxCount ? (
                          <FormFeedback type="invalid">
                            {validation.errors.maxCount}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Ticket Order No</Label>
                        <Input
                          name="ticketOrder"
                          label="Order"
                          type="text"
                          placeholder="Enter the order no .Eg: 1,2,3"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ticketOrder || ""}
                          invalid={
                            validation.touched.ticketOrder &&
                              validation.errors.ticketOrder
                              ? true
                              : false
                          }

                        />
                        {validation.touched.ticketOrder &&
                          validation.errors.ticketOrder ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ticketOrder}
                          </FormFeedback>
                        ) : null}

                      </div>
                      {!isEdit?
                      <>
                      <div className="mb-3">
                        <Label className="form-label">Select Event</Label>
                        <Select
                          value={selectedEventForAdd}
                          placeholder="Select Event"
                          onChange={(data) => {
                            validation.setFieldValue("eventSlug", data.value)
                            getEventVenueForAdd(data.value)
                            setSelectedEventForAdd(data)

                          }}
                          options={eventList}
                          width="100%"
                          className="select2-selection"
                        />

                        {validation.touched.eventSlug && validation.errors.eventSlug ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.eventSlug}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Select Venue</Label>
                        <Select
                          value={selectedVenueForAdd}
                          placeholder="Select Venue"
                          onChange={(data) => {
                            validation.setFieldValue("venueId", data.value)
                            setSelectedVenueForAdd(data)
                          }}
                          options={eventVenuesForAdd}
                          width="100%"
                          className="select2-selection"
                        />
                        {validation.touched.venueId && validation.errors.venueId ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.venueId}</FormFeedback>
                        ) : null}
                      </div>
                      </>:
                     
                        <div className="mb-3">
                          <Label className="form-label">Status</Label>

                          <select className="form-select pageSize" name="status" onChange={(e) => setStatus(e.target.value)}>
                            <option value="" >Select Status</option>
                            <option value="active" selected={tickets?.ticketStatus === "active"}>Active</option>
                            <option value="inactive" selected={tickets?.ticketStatus === "inactive"} >Inactive</option>
                            <option value="sold-out" selected={tickets?.ticketStatus === "sold-out"} >Sold Out</option>


                          </select>

                        </div>
                      }



                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          {isLoading ? "Loading...." : "Save"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EventTickets);
