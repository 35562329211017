import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    Input,
    Form,
    UncontrolledTooltip,
} from "reactstrap";
import { createEventVenue, createOrganizerCoupon, getAllEventByOrganizer, getAllEvents, getAllTicketByEvent, getAllTicketByEventAndVenue, getEventVenuesBySlug, getOrganizerCoupon, updateEventVenue, updateOrganizerCoupon } from "api";
import Select from "react-select";
import Breadcrumbs from "components/Common/Breadcrumb";
import Spinners from "components/Common/Spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import moment from "moment";


function OrganizerCoupon() {
    document.title = "Organizer Coupon List | EventoX";

    const navigate = useNavigate();
    const [coupons, setCoupons] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [ticketList, setTicketList] = useState([])
    const [venueList, setVenueList] = useState([])
    const [selectedVenue, setSelectedVenue] = useState(null)
    const [selectedTicketType, setSelectedTicketType] = useState(null)
    const [eventOptions, setEventOptions] = useState([])
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedDiscountType, setSelectedDiscountType] = useState(null)
    const [isVenueExist, setIsVenueExist] = useState(false)
    const [statusOptions, setStatusOptions] = useState([
        {value:'active',label:'Active'},
        {value:'inactive',label:'Inactive'}
    ])
    const [selectedStatus, setSelectedStatus] = useState(null)
    const discountTypeOptions = [
        {value:'percentage',label:'Percentage'},
        {value:'fixed',label:'Fixed'}
    ]

    const validation = useFormik({
        initialValues:{
            code:"",
            discountType:"",
            discountAmount:"",
            eventId:"",
            maxCount:0,
            ticketTypeId:"",
            status:'active'
        },
        validationSchema:Yup.object({ 
            code:Yup.string().required("Coupon code is required"),
            discountType:Yup.string().required("Discount type is required"),
            discount:Yup.number().required("Discount amount is required"),    
            eventId:Yup.string().required("Event is required"),
            maxCount:Yup.number().required("Max count is required"),
            ticketTypeId:Yup.string().required("Ticket is required"),
        }),
        onSubmit:async(values) => {
            console.log("🚀 ~ onSubmit:async ~ values:", values)
            
            try{
            if(isEdit){
                const updateResp = await updateOrganizerCoupon(values.couponId,values);
                toast.success("Coupon updated successfully");
                setSelectedStatus(null)
                setIsEdit(false)
                toggle();
            }else{
                const createResp = await createOrganizerCoupon(values);
                setSelectedStatus(null)
                setIsEdit(false)
                toggle();
                toast.success("Coupon created successfully");
                
            }
            fetchAll();
        }catch(error){
                toast.error("Something went wrong");
                console.log(error);
            }
        }
})



    useEffect(() => {

        fetchAll();
        return () => {

        }
    }, [])
    const toggle = () => {
        setModal(!modal);
        validation.resetForm();
    };

    const fetchAll = async () => {
        try {
            setIsLoading(true)
            const respAllCoupon = await getOrganizerCoupon();
            console.log("🚀 ~ fetchAll ~ respAllCoupon:", respAllCoupon)
            const respAllEvent = await getAllEventByOrganizer();
            setCoupons(respAllCoupon)
            const eventOptions = respAllEvent.map((item) => {
                return {
                  value: item.eventId,
                  label: item.eventName,
                  slug:item.slugId
                }
              })
              setEventOptions(eventOptions);

        } catch (error) {
            console.log(error);
        }finally{
            setIsLoading(false)
        }
    }
    const handleEventSelection = async (data) => {
        const slugId = data.slug
        const respVenue = await getEventVenuesBySlug(slugId)
        const respTicket = await getAllTicketByEvent(slugId)
        setSelectedTicketType("")
        setSelectedVenue("")
        if(respVenue.length > 0){
            setIsVenueExist(true)
            const venueOptions = respVenue.map((item) => {
                return {
                  value: item.venueId,
                  label: item.venueName,
                }
              })    
            setVenueList(venueOptions)
        }else{
            setIsVenueExist(false)
            setVenueList([])
        }
        if(respTicket.length > 0){
            const ticketOptions = respTicket.map((item) => {
                return {
                  value: item.ticketTypeId,
                  label: item.ticketType,
                  venueId:item.venueId
                }
              })
            setTicketList(ticketOptions)
        }else{
            setTicketList([])
        }
        
        setSelectedEvent(data)
        validation.setValues({...validation.values,eventId:data.value})
    }
    const handleVenueSelection = async (data) => {
        setSelectedVenue(data)
        setSelectedTicketType("")
        const ticketResp = await getAllTicketByEventAndVenue(selectedEvent.slug,data.value)
        if(ticketResp.length > 0){
            const ticketOptions = ticketResp.map((item) => {
                return {
                  value: item.ticketTypeId,
                  label: item.ticketType,
                }
              })
            setTicketList(ticketOptions)
        }else{
            setTicketList([])
        }

    }

   
   
    const columns = useMemo(
        () => [

            {
                header: 'Coupon Code',
                accessorKey: 'code',
                enableColumnFilter: false,
                enableSorting: true,

            },

            {
                header: 'Discount',
                accessorKey: 'discount',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    //if discount type is percentage
                    if(cell.row.original.discountType === 'percentage'){
                        return <span>{cell.getValue()}%</span>
                    }else{
                        return <span>{cell.getValue()}</span>
                    }
                }
            },
            {
                header: 'Discount Type',
                accessorKey: 'discountType',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                headerName: 'Max Count',
                accessorKey: 'maxCount',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header:"Total Used",
                accessorKey:"totalUsed",
                enableColumnFilter: false,
                enableSorting: true,
            },

            {
                header: 'Event',
                cell: (cell) => {
                    return `${cell.row.original.Event?.eventName} ${cell.row.original.TicketType?.Venue!==null?`(${cell.row.original.TicketType?.Venue?.venueName})`:""} `
                }
            },
            {
                header: 'Ticket',
                accessorKey: 'TicketType.ticketType',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Created At',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    return <span>{moment(new Date(cell.getValue())).format('DD-MM-YYYY HH:mm:ss')}</span>
                }
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                  return (
                    <div>
                      {
                        cell.getValue() === 'active' ? (
                          <span className="badge badge-soft-primary font-size-11 m-1">{cell.getValue()}</span>
                        ) : (
                          <span className="badge badge-soft-danger font-size-11 m-1">{cell.getValue()}</span>
                        )
                      }
        
                    </div>
                  );
                },
              },


            {
                header: 'Action',
                cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="card-drop">
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">

                                {/* <DropdownItem
                                    onClick={() => {
                                        const data = cellProps.row.original;
                                        onClickDelete(data);
                                    }}>
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                                    Delete
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </DropdownItem> */}
                                <DropdownItem onClick={()=>{
                                    setIsEdit(true)
                                    setModal(true)
                                    validation.setValues({
                                        couponId:cellProps.row.original.couponId,
                                        code:cellProps.row.original.code,
                                        discountType:cellProps.row.original.discountType,
                                        discount:cellProps.row.original.discount,
                                        eventId:cellProps.row.original.eventId,
                                        status:cellProps.row.original.status,
                                        maxCount:cellProps.row.original.maxCount

                                    })
                                    setSelectedStatus({value:cellProps.row.original.status,label:cellProps.row.original.status})
                                    setSelectedEvent({value:cellProps.row.original.eventId,label:cellProps.row.original.eventName})
                                }}>
                                    <i className="mdi mdi-pencil font-size-16 text-primary me-1" id="deletetooltip"></i>
                                    Edit
                                </DropdownItem>

                            </DropdownMenu>

                        </UncontrolledDropdown>
                    );
                }
            },
        ],
        []
    );
  
    const handleClickNew = () => {
        toggle();
    }

    //meta title
    document.title = "Organizer Coupon List | EventoX";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Coupon List" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setIsLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                           
                                            <Row>
                                                <TableContainer
                                                    columns={columns}
                                                    data={coupons || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    SearchPlaceholder="Search..."
                                                    isCustomPageSize={true}
                                                    isAddButton={true}
                                                    handleUserClick={handleClickNew}
                                                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                                    buttonName="New Coupon"
                                                    tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    pagination="pagination"
                                                />
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </Container>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">{!!isEdit?"Edit Coupon":"Add New Coupon"}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={e=>{
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Coupon Code</Label>
                                    <Input type="text" name="code" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.code} invalid={validation.touched.code && validation.errors.code?true:false}/>
                                    {validation.touched.code && validation.errors.code?<FormFeedback type="invalid">{validation.errors.code}</FormFeedback>:null}

                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Select Event</Label>
                                    <Select
                                        value={selectedEvent}
                                        onChange={handleEventSelection}
                                        options={eventOptions}
                                          className="select2-selection"
                                          menuContainerStyle={{'zIndex': 999}}
                                          styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                </div>
                                {isVenueExist?
                                <div className="mb-3">
                                    <Label className="form-label">Select Venue</Label>
                                    <Select
                                        value={selectedVenue}
                                        onChange={handleVenueSelection}
                                        options={venueList}
                                          className="select2-selection"
                                          menuContainerStyle={{'zIndex': 999}}
                                          styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                </div>
                                :null}
                               
                                <div className="mb-3">
                                    <Label className="form-label">Select Ticket</Label>
                                    <Select
                                        value={selectedTicketType}
                                        onChange={(data) => {
                                            setSelectedTicketType(data)
                                            validation.setValues({...validation.values,ticketTypeId:data.value})
                                        }}
                                        options={ticketList}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Discount Type</Label>
                                    <Select
                                        value={selectedDiscountType}
                                        onChange={(data) => {
                                            setSelectedDiscountType(data)
                                            validation.setValues({...validation.values,discountType:data.value})
                                        }}
                                        options={discountTypeOptions}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                </div>
                                
                                <div className="mb-3">
                                    <Label className="form-label">Discount Amount</Label>
                                    <Input type="number" name="discount" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.discount} invalid={validation.touched.discount && validation.errors.discount?true:false}/>
                                    {validation.touched.discount && validation.errors.discount?<FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>:null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Max Count</Label>
                                    <Input type="number" name="maxCount" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.maxCount} invalid={validation.touched.maxCount && validation.errors.maxCount?true:false}/>
                                    {validation.touched.maxCount && validation.errors.maxCount?<FormFeedback type="invalid">{validation.errors.maxCount}</FormFeedback>:null}
                                </div>
                                
                                <div className="mb-3">
                                    <Label className="form-label">Status</Label>
                                    <Select
                                        value={selectedStatus}
                                        onChange={(data) => {
                                            setSelectedStatus(data)
                                            validation.setValues({...validation.values,status:data.value})
                                        }}
                                        options={statusOptions}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <button type="submit" className="btn btn-success save-user">Submit</button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default OrganizerCoupon
